import ajax from 'axios'
// import { getStorages } from '@/Utils/localStorage'
import store from '@/store'
import { setToken, setEquipmentNo, removeToken, removeEquipmentNo } from '@/utils/cookie'
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);

const axios = ajax.create({
	// baseURL: process.env.NODE_ENV === "production" ? "http://api.inclusivekredit.com:20222" : "/", // 测试环境
	// baseURL: 'http://218.75.109.186:22222', //
	// baseURL: '/api', //
  baseURL: '/api',
  // baseURL: 'https://gkdlo.money-plus-app.in/',
  //https://gkdlo.money-plus-app.in/
	timeout: 5000,//
	withCredentials: true
})

// async function init() {
//   await this.list1();
//   this.list2();
// },

//
axios.interceptors.request.use(function (config) {
console.log('flutter_baseInfo********',store.state.flutter_baseInfo)
// window.flutter_inappwebview.callHandler("getBaseInfo").then((result) =>{
//  setToken(result.token)
// })
let token = (JSON.parse(localStorage.getItem("BaseInfo"))).token;
setToken(token)


// let token = '304ca53a-c4c5-48b0-bcfa-3455857f6121'
// 	setToken(token)

return config

}, function (error) {
	return Promise.reject(error)
})


axios.interceptors.response.use(function (response) {
  if(response.data.code===1000){
    // removeToken()
		// removeEquipmentNo()
    // window.flutter_inappwebview.callHandler("loginOut");
  }
  if(response.data.code===9999){
    Toast(response.data.msg)
    // removeToken()
		// removeEquipmentNo()
    // window.flutter_inappwebview.callHandler("loginOut");
  }


	// const data = window.baseAndroid.parameterDecryption(response.data.mkljdjkmdklw34gh)

	// let res = JSON.parse(data)
	// console.log(49, res);
	// console.log('request', res, res['3B373C3D'], res['3C392C39']);
	// let res = data1['3C392C39']
	// if (res['3B373C3D'] == 1000) {
	// 	removeToken()
	// 	removeEquipmentNo()
	// 	window.baseAndroid.logout()//code===1000
	// 	return Promise.reject(res['3C392C39']).catch(() => { })
	// } else if (res['3B373C3D'] === 0) {
	// 	// Toast(res['352B3F']);
	// 	if (Object.prototype.toString.call(res['3C392C39']) === '[object Object]') {
	// 		res['3C392C39'].msg = res['352B3F']
	// 	}
	// 	return res['3C392C39']
	// } else if (res['3B373C3D'] === 100) {

	// } else {
	// 	Toast(res['352B3F']);
  return response.data

	// }
}, function (error) {
	return Promise.reject(error)
})

export default ({ url, method = 'GET', data = {},params = {} }) => {
	return axios({
		url,
		method,
		data,
		params
	})
}
// export default axios

<template>
  <div id="app">
    <!-- <nav></nav> -->
    <!-- <keep-alive exclude="repaymentDetails,extend"> -->
    <router-view></router-view>
    <!-- </keep-alive> -->
    <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>
import { getCustomerServiceConfig } from "@/api/index";
export default {
  data() {
    return {};
  },
  async mounted() {
    // if (window.flutter_inappwebview.callHandler) {
    //   await window.flutter_inappwebview
    //     .callHandler("getBaseInfo", "")
    //     .then((result) => {
    //       console.log("app.vue:", result);
    //       localStorage.setItem("BaseInfo", JSON.stringify(result));
    //       localStorage.setItem("appCode", result.appCode);
    //       bsInfo = {
    //         appCode: Number(result.appCode),
    //         version: result.versionCode,
    //         userCode: Number(result.customerId),
    //       };
    //     })
    //     .catch((error) => {
    //       console.log(`getBaseInfo-error: "${error}"`);
    //       window.flutter_inappwebview.callHandler("loginOut");
    //     });
    // } else {
    //   await window.flutter_inappwebview
    //     .callHandler(
    //       "getBaseInfo",
    //       setTimeout(function () {}),
    //       JSON.stringify([""])
    //     )
    //     .then((result) => {
    //       console.log("app.vue:", result);
    //       localStorage.setItem("BaseInfo", JSON.stringify(result));
    //       localStorage.setItem("appCode", result.appCode);
    //       bsInfo = {
    //         appCode: Number(result.appCode),
    //         version: result.versionCode,
    //         userCode: Number(result.customerId),
    //       };
    //     });

    // }
    console.log("------app.vue");
    let bsInfo;
    // let cId;
    // bsInfo= {
    //       appCode: 10056,
    //       version: "1",
    //       // userCode:15413,
    //       // userCode:15395,
    //       userCode:10056,

    //     };
    //     localStorage.setItem("BaseInfo", JSON.stringify(bsInfo));

    await window.flutter_inappwebview
      .callHandler("getBaseInfo")
      .then((result) => {
        console.log("app.vue:", result);
        localStorage.setItem("BaseInfo", JSON.stringify(result));
        localStorage.setItem("appCode", result.appCode);
        bsInfo = {
          appCode: Number(result.appCode),
          version: result.versionCode,
          userCode: Number(result.customerId),
        };
      })
      .catch((error) => {
        console.log(`getBaseInfo-error: "${error}"`);
        window.flutter_inappwebview.callHandler("loginOut");
      });
    this.$store.commit("getFromApp", bsInfo);
    // console.log("2:", this.$store.state.flutter_baseInfo,this.$store.state.flutter_cId);
  },

};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

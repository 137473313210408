
import request from '@/utils/request'

export function huoQuAuthStepV2ForH5(data) {
	return request({
		url:  '/user/huoQuAuthStepV2',
		method: 'POST',
		data
	})
}

export function huoQuIndexInfo(data) {
	return request({
		url:  '/biz/home/huoQuIndexInfo',
		method: 'POST',
		data
	})
}
export function getCertificateInfo(data) {
	return request({
		url:  '/user/extend/getCertificateInfo',
		method: 'POST',
		data
	})
}
export function fetchUserExtFieldSelectItems(data) {
	return request({
		url:  '/user/userExt/fetchUserExtFieldSelectItems',
		method: 'POST',
		data
	})
}
export function submitUserExt(data) {
	return request({
		url:  '/user/userExt/submitUserExt',
		method: 'POST',
		data
	})
}
export function getBankNameCodeV2(data) {
	return request({
		url:  '/user/ifsc/getBankNameCodeV2',
		method: 'POST',
		data
	})
}
// --------------bankcard
export function yongHuBangKa(data) {
	return request({
		url:  '/user/debit/yongHuBangKa',
		method: 'POST',
		data
	})
}
export function huoQuPreRiskStatus(data) {
	return request({
		url:  '/biz/home/huoQuPreRiskStatus',
		method: 'POST',
		data
	})
}
export function huoQuPopupSwitch(data) {
	return request({
		url:  '/user/guidancePopup/huoQuPopupSwitch',
		method: 'POST',
		data
	})
}
export function huoQuGuideShowInfo(data) {
	return request({
		url:  '/user/guidancePopup/huoQuGuideShowInfo',
		method: 'POST',
		data
	})
}
export function createMemberLink(data) {
	return request({
		url:  '/biz/pay/createMemberLink',
		method: 'POST',
		data
	})
}
export function huoQuPopupInfo(data) {
	return request({
		url:  '/user/customerVisa/huoQuPopupInfo',
		method: 'POST',
		data
	})
}
export function preserveCustomerVisa(data) {
	return request({
		url:  '/user/customerVisa/preserveCustomerVisa',
		method: 'POST',
		data
	})
}
// loan--------------------------------------
export function huoQuChanPinList(data) {
	return request({
		url:  '/biz/product/huoQuChanPinList',
		method: 'POST',
		data
	})
}
export function chuangJianOrder(data) {
	return request({
		url:  '/biz/borrow/chuangJianOrder',
		method: 'POST',
		data
	})
}
export function huoQuLiShiOrderList(data) {
	return request({
		url:  '/biz/borrow/huoQuLiShiOrderList',
		method: 'POST',
		data
	})
}
// ------repay

export function huoQuDaiHuanKuanInfo(data) {
	return request({
		url:  '/biz/pay/huoQuDaiHuanKuanInfo',
		method: 'POST',
		data
	})
}
export function huoQuHuanKuanInfo(data) {
	return request({
		url:  '/biz/pay/huoQuHuanKuanInfo',
		method: 'POST',
		data
	})
}

export function getRepayPayCompanyInfo(data) {
	return request({
		url:  '/biz/pay/getRepayPayCompanyInfo',
		method: 'POST',
		data
	})
}
export function createHuanKuanLink(data) {
	return request({
		url:  '/biz/pay/createHuanKuanLink',
		method: 'POST',
		data
	})
}
export function huoQuYanQiOrderInfo(data) {
	return request({
		url:  '/biz/pay/huoQuYanQiOrderInfo',
		method: 'POST',
		data
	})
}
export function shangChuanUtr(data) {
	return request({
		url:  '/biz/utr/shangChuanUtr',
		method: 'POST',
		data
	})
}
// profile
export function getCustomerServiceConfig(data) {
	return request({
		url:  '/biz/app/getCustomerServiceConfig',
		method: 'POST',
		data
	})
}
export function deleteCustomerInfoByOneself(data) {
	return request({
		url:  '/user/deleteCustomerInfoByOneself',
		method: 'POST',
		data
	})
}
export function tuiChu(data) {
	return request({
		url:  '/user/tuiChu',
		method: 'POST',
		data
	})
}
//判定用户评价是否提交
export function isSubmit(data) {
	return request({
		url:  '/user/score/isSubmit',
		method: 'POST',
		data
	})
}
//去评价
export function submitScore(data) {
	return request({
		url:  '/user/score/submitScore',
		method: 'POST',
		data
	})
}



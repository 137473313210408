import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/home/index'),

  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index'),
  },
  {
    path: '/extend',
    name: 'extend',
    component: () => import('@/views/extend/index'),

  },

  {
    path: '/bankcard',
    name: 'bankcard',
    component: () => import('@/views/bankcard/index'),
  },

  {
    path: '/deletePersonaldata',
    name: 'deletePersonaldata',
    component: () => import('@/views/deletePersonaldata/index'),

  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index'),

  },
  {
    path: '/delete',
    name: 'delete',
    component: () => import('@/views/delete/index'),

  },

  {
    path: '/borrowingSucceeded',
    name: 'borrowingSucceeded',
    component: () => import('@/views/borrowingSucceeded/index'),

  },

  // ----------------
  {
    path: '/identification',
    name: 'identification',
    component: () => import('@/views/identification/index'),
  },
  {
    path: '/ifsc',
    name: 'ifsc',
    component: () => import('@/views/ifsc/index'),

  },
  {
    path: '/hasVisa',
    name: 'hasVisa',
    component: () => import('@/views/hasVisa/index'),
  },
  {
    path: '/customerService',
    name: 'customerService',
    component: () => import('@/views/customerService/index'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/index'),
  },
  {
    path: '/personalInformation',
    name: 'personalInformation',
    component: () => import('@/views/personalInformation/index'),
  },
  {
    path: '/loan',
    name: 'loan',
    component: () => import('@/views/loan/index'),
  },
  {
    path: '/loanRecords',
    name: 'loanRecords',
    component: () => import('@/views/loanRecords/index'),

  },
  {
    path: '/getLoan',
    name: 'getLoan',
    component: () => import('@/views/getLoan/index'),

  },
  {
    path: '/repayment',
    name: 'repayment',
    component: () => import('@/views/repayment/index'),

  },
  {
    path: '/repaymentDetails',
    name: 'repaymentDetails',
    component: () => import('@/views/repaymentDetails/index'),

  },
  {
    path: '/utr',
    name: 'utr',
    component: () => import('@/views/utr/index'),

  },
  {
    path: '/Processing',
    name: 'Processing',
    component: () => import('@/views/Processing/index'),

  },


]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   let appId = localStorage.getItem("appCode")
//   document.title = `${appId}-${to.name}`
//   // 获取页面名称
//   const pageName = to.name;
//   // 发送页面名称到 Google Analytics
//   window.gtag("event", "page_view", {
//     page_title: pageName,
//   });

//   // 记录页面进入时间
//   const enterTime = Date.now();

//   // 在路由离开守卫中计算页面停留时间
//   to.meta.leaveGuard = router.afterEach((to, from) => {
//     const stayTime = Date.now() - enterTime;

//     // 发送页面停留时间到 Google Analytics
//     window.gtag("event", "stay_time", {
//       value: stayTime,
//       event_category: "timing",
//       event_label: pageName,
//     });

//     // 移除路由离开守卫
//     to.meta.leaveGuard();
//   });
//   next()

// })

export default router
